import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import { get, times, capitalize } from 'lodash'
import Layout from '../components/theme/layout'
import PostCard from '../components/contentful/post-card'
import SEO from '../components/seo'
import styles from '../scss/blog.scss'
import { ButtonGroup } from 'react-bootstrap'
import { blogUrl, findAncestorByClassNameInEventPath, buildBreadcrumbsItem, blogPostCategoryDescriptions, blogPostCategoryImages } from "../helpers.js"
import { ALL_BLOG_CATEGORIES_LABEL } from "../constants.js"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import StickyShare from "../components/sticky-share/sticky-share";

class BlogPostsListTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.siteUrl = get(this.props, 'data.site.siteMetadata.siteUrl')
    this.page = get(this.props, 'pageContext.page', 1)
    this.category = get(this.props, 'pageContext.category', ALL_BLOG_CATEGORIES_LABEL) || ALL_BLOG_CATEGORIES_LABEL
    this.pages = get(this.props, 'pageContext.pages', 1)
    this.posts = get(this.props, 'pageContext.posts', [])
    this.contentfulNoIndexBlogs = get(this.props, 'data.site.siteMetadata.contentfulNoIndexBlogs', [])
    
    this.allCategories = get(this.props, 'pageContext.allCategories', [])
    const capitalizedCategory = capitalize(this.category)    
    this.breadcrumbs = [
      buildBreadcrumbsItem('Blog', blogUrl(null, 1, this.props.location.origin))
    ]
    
    if (this.category !== ALL_BLOG_CATEGORIES_LABEL) {
      this.breadcrumbs.push(buildBreadcrumbsItem(`${capitalizedCategory}${this.page > 1 ? ' - Page ' + this.page : ''}`, this.props.location.href, this.handleBreadcrumbsTrigger))
    }
    
    this.seo = {
      title: 'Learn More' + (this.category !== ALL_BLOG_CATEGORIES_LABEL ? ` About ${capitalizedCategory}` : '')  + ' | Honest Paws®',
      description:  'Learn how to take better care of your fur baby and improve their overall health.',
      children: []
    }

    this.state = {
      breadcrumbs_visible: false,
      filtersOpen: false
    }

    this.seo.paginationAttributes = {
      currentLink: blogUrl(this.category, this.page, this.siteUrl)
    }
    if (this.pages > 1 && this.page > 1 ) {
      this.seo.paginationAttributes.prevLink = blogUrl(this.category, this.page - 1, this.siteUrl)
    }
    if (this.pages > 1 && this.page < this.pages ) {
      this.seo.paginationAttributes.nextLink = blogUrl(this.category, this.page + 1, this.siteUrl)
    }

    if (this.contentfulNoIndexBlogs.indexOf(this.category) >= 0 ) {
      this.seo.disallowIndexing = true
    }

    // this.handleFiltersButtonChange = this.handleFiltersButtonChange.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.handleBreadcrumbsTrigger = this.handleBreadcrumbsTrigger.bind(this)
  }
  
  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }
  
  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  /**
   * Handles breadcrumbs box visualization
   * @param  {Event} e
   * @return {void}
   */
  handleBreadcrumbsTrigger(e) {
    this.setState({
      breadcrumbs_visible: !this.state.breadcrumbs_visible
    })
  }
  
  /**
   * Handles click event so we can close the cart when it a click is made outside it
   * @param  {event} e
   * @return {void}
   */
  handleDocumentClick(e) {
    // These are all the classes that will prevent this from happening
    if (!findAncestorByClassNameInEventPath(e, ['filter-btn-group-container-inner','.btn-toggle-breadcrumbs', '.breadcrumbs-list-container']) && this.state.breadcrumbs_visible) {
      this.handleBreadcrumbsTrigger()
    }
  }

  
  render() {
    const prevLink = (this.pages > 1 && this.page > 1 ) ? blogUrl(this.category, this.page - 1) : null
    const nextLink = (this.pages > 1 && this.page < this.pages ) ? blogUrl(this.category, this.page + 1) : null
    return (
      <Layout location={this.props.location} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden" containerClassName={`pb-5 blog blog-category-page ${this.category ? this.category : ''} blog-${this.page}`}>
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <StickyShare shareDialogTitle={this.seo.title} shareDialogText={this.seo.description}/>

        {/*<nav className="container">*/}
          {/*<div className="row">*/}
            {/*<div className="col-12 col-lg-9 mx-auto breadcrumbs-component-container mb-2">*/}
              {/*<button className="btn btn-toggle-breadcrumbs" onClick={this.handleBreadcrumbsTrigger}><span>...</span></button>*/}
              {/*<Breadcrumbs items={ this.breadcrumbs } location={ this.props.location }*/}
                           {/*className={`breadcrumbs-list-container ${!this.state.breadcrumbs_visible ? 'd-none' : ''}`}/>*/}
            {/*</div>*/}
          {/*</div>*/}
        {/*</nav>*/}

        <div className="gray-bg">
          <div className="container">
            <div className="row pt-lg-5 pb-lg-0 py-5">
              <div className="col-12 col-lg-4 offset-lg-2 mt-lg-4 text-left">
                <h1>{capitalize(this.category)}</h1>
                <div className="category-description">{blogPostCategoryDescriptions[this.category]}</div>
              </div>
              <div className="col-lg-5 d-none d-lg-block text-center">
                <img src={`/img/blog-categories/${blogPostCategoryImages[this.category]}`} alt="category image"/>
              </div>
            </div>
          </div>
        </div>

        <div className="container blogs-section">
          <div className="row">
            <div className="col-12 col-lg-9 mx-auto">
              <h2 className="mt-5 text-primary">Explore Articles</h2>
              <div className="d-flex row">
                {this.posts.map((post, index) => {
                  return <PostCard
                    title={ post.title } url={`https://www.honestpaws.com/${post.url}`} image={ post.heroImage } shortDescription={ post.shortDescription }
                    key={ index }
                    version={2}
                    className="col-12 col-lg-4 my-4" />
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="paginator-container mt-5 text-center">
            {prevLink ? <a target="_top" href={ prevLink } className="btn btn-link d-md-none mr-3">Prev</a> : ''}
            {nextLink ? <a target="_top" href={ nextLink } className="btn btn-link d-md-none">Next</a> : ''}
            <ButtonGroup className="d-none d-md-block">
              {prevLink ? <a target="_top" href={ prevLink } className="btn btn-link btn-prev">Prev</a> : ''}
              { this.pages > 1 ? times(this.pages, (index) => {
                const page = index + 1
                let buttonClass = 'btn-link'
                if (this.page === page) {
                  buttonClass += ' active'
                }
                const link = blogUrl(this.category, page)
                return <a target="_top" href={ link } className={ `btn ${buttonClass}` } key={ index }>{ page }</a>
              }) : ''}
              {nextLink ? <a target="_top" href={ nextLink } className="btn btn-link btn-next">Next</a> : ''}
            </ButtonGroup>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostsListTemplate

export const BlogPostsListQuery = graphql`
  query BlogPostsListQuery {
    site {
      siteMetadata { 
        siteUrl,
        contentfulNoIndexBlogs
      }
    }
  }
`